import axios from 'axios';
import { PROFILE, STATISTIC, SUBSCRIPTION } from '../endpoints';
import { api } from '..';

interface UsersResponseI extends MetaDataI {
    users: User[]; 
}

const ProfileService = {
    async getUsers(page: number, searchValue?:string) {
        const params = {page};
        const response: UsersResponseI = await api.get(PROFILE.GET_USERS, {...params}); 
        return response; 
    },
    async getProfile(id:string) {
        const result: User = await api.get(`${PROFILE.GET_PROFILE}/${id}`);
        return result; 
    },
    async getProfileBySubId() {
        return await api.get(PROFILE.GET_PROFILE);
    },
    async getProfileByEmail(email:string) {
        const result: User = await api.get(PROFILE.GET_PROFILE_BY_EMAIL, { email });
        return result;
    },
    async editUserProfile(data:any) {
        const token = sessionStorage.getItem('token'); 
        const response: User = await api.put(PROFILE.EDIT_USER, {...data}, {headers: {
            Authorization: `Bearer ${token}`
        }}); 
        return response; 
    },
    async createUser(data:any) {
        return await api.post(PROFILE.CREATE_USER, data); 
    },
    async subscribe(data:any) {
        return await api.post(SUBSCRIPTION.SUBSCRIBE, data); 
    },
    async getSubscription() {
        const result: SubscriptionDataI = await api.get(SUBSCRIPTION.GET_SUBSCRIPTION);
        return result;
    },
    async cancelSubscription(subscriptionId: string) {
        const params = {subscriptionId};
        return await api.put(SUBSCRIPTION.CANCEL_SUBSCRIPTION, {}, {params}); 
    },
    async deleteAccount(id: string) {
        return await api.delete(PROFILE.DELETE_ACCOUNT, { id }); 
    },
    async changePassword(data:any) {
        const path = process.env.REACT_APP_AUTH0_DOMAIN
        return await axios.post(`https://${path}/dbconnections/change_password`, {...data}); 
    },
    async generateOneTimeCode(id: string) {
        const response: {code: string} = await api.get(`${PROFILE.GENERATE_ONE_TIME_CODE}/${id}/code`); 
        return response; 
    },
    async validateDeviceCode(code: string) {
        const response: {item_id: string} = await api.get(`${PROFILE.VALIDATE_DEVICE_CODE}/${code}`); 
        return response; 
    },
    async getActionLogs(user_id: string) {
        const response: LogsResponse[] = await api.get(`${STATISTIC.LOGS_ACTIONS}/${user_id}`); 
        // const response: LogsResponse[] = await api.get(`https://c427-77-75-145-183.ngrok-free.app/api/statistic_manager/v1/user_actions/${user_id}`); 
        return response; 
    },
};

export default ProfileService; 