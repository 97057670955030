import React, { useMemo, useState } from 'react'; 
import Footer from '../components/Footer/intex';
import Navbar from '../components/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import NavbarAdmin from '../components/Navbar/NavbarAdmin';
import logo from '../assets/images/nftrends-logo-blue-new.svg';
import logo_white from '../assets/images/Nftrends-logo-white-new.svg';
import { Roles, appLogo, isSetBuyerFlowStyleRoutes, paths, shortFormatRole } from '../common/constants';
import SubscriptionInformer from '../components/Notifications/SubscriptionInformer';
import { useGetSubscriptionStatus } from '../hooks/useGetSubscriptionStatus';
import { useAppContext } from '../contexts/AppContext';

interface Props {
    children: JSX.Element, 
}

function AppLayout({children}:Props) {
  const { logoutHandler, userData, isAuth, login } = useAuthContext(); 
  const {isOpenDigigtalPassport, isNoBuerFlow} = useAppContext();
  const [handleLogo, setHandleLogo] = useState(false);
  const location = useLocation(); 
  const navigate = useNavigate(); 

  const {showSubscriptionInformer, setShowSubscriptionInformer, reminderText} = useGetSubscriptionStatus();

  const isByerException = useMemo(() => {
    const location = window.location.pathname; 
    if(location?.includes('/intelectual_property') || location?.includes('/privacy_policy') || location?.includes('/terms_of_use') || location?.includes('/success_approve') || location?.includes('/archive')) {
      return true;
    } else {
      return false; 
    }
  }, [window.location.pathname]); 

  const handleClick = () => {
    setHandleLogo(prev => !prev); 
    if(location.pathname.includes('admin')) {
      navigate('/admin/people');
    } else {
      navigate('/dashboard/items');
    }
  }

  const setStyle = () => {
    if(location.pathname.includes('/item_qr')) {
      return 'bg-white lg:bg-gray-bg'; 
    }
    if(location.pathname.includes('/admin') || location.pathname.includes('/intelectual_property') || location.pathname.includes('/privacy_policy') || location.pathname.includes('/terms_of_use')  || location.pathname.includes('/archive') || location.pathname.includes('/dpp_example') || location.pathname.includes('/success_approve')) {
      return 'bg-white';
    }

    if(isSetBuyerFlowStyleRoutes[location.pathname]) {
      return 'bg-blue-normal';
    } 

    return isNoBuerFlow ? 'bg-white' : 'bg-blue-normal'; 
  }

  const handleLogin = () => {
    if(location.pathname.includes('unknown_error') || isAuth) {
      logoutHandler();
    } else {
      login('login'); 
    }
  }
  
  return (
    <div className={`${setStyle()} flex flex-col min-h-screen font-base relative`}>
       {showSubscriptionInformer && !isByerException && !isNoBuerFlow && !isOpenDigigtalPassport && userData?.userRole !== Roles.EXPERT && userData?.userRole !== Roles.BOOTSTRAPPED && <SubscriptionInformer handlerClose={() => { setShowSubscriptionInformer(false); }} text={reminderText}/>}
        {!location.pathname.includes('item_qr') && !location.pathname.includes('dpp_example') && !location.pathname.includes(paths.ONBOARDING) && <div className={`flex fixed lg:hidden text-[25px] z-[100] ${(isNoBuerFlow || isByerException) ? 'bg-white border-b border-b-[#CBCBCB]' : 'bg-blue-darkest'} w-full items-center justify-between pl-[15px] pr-[24px] pt-1 font-medium h-[64px]`}>
            <div onClick={handleClick}>
               <img className='w-[250px]' src={(isNoBuerFlow || isByerException) ? appLogo.black : appLogo.white} alt='logo'/>
            </div>
           {location.pathname !== '/' && <button className={`text-[14px] ${(isNoBuerFlow || isByerException) ? 'text-blue-normal' : 'text-white'} font-medium`} onClick={handleLogin}>
             {(!isAuth && location.pathname !== '/unknown_error') ? 'Log in' : 'Log out'}
             {isAuth ? <span className={'pl-1'}>{`(${shortFormatRole[userData?.userRole ?? '']})`}</span> : null}
            </button>}
           
          </div>}
        <div className={`flex-1 ${(!location.pathname.includes('dpp_example') && !location.pathname.includes(paths.ONBOARDING)) ? 'pb-[56px] lg:pb-[56px] pt-[64px]' : 'pb-0 lg:pb-0 pt-0'}  lg:pt-0  w-full lg:w-none`}>
             {children}
        </div>
        {(location.pathname !== '/' && isAuth && !location.pathname.includes('item_qr')) && !location.pathname.includes('dpp_example') && !isByerException && !location.pathname.includes(paths.ONBOARDING) && location.pathname !== '/user_moderation' && location.pathname !== '/unknown_error' && <div className='flex lg:hidden fixed lg:sticky bottom-0 w-full z-[150]'>
           {userData?.userRole === Roles.UBER_ADMIN ? <NavbarAdmin handleLogo={handleLogo}/> : <Navbar handleLogo={handleLogo}/>}
        </div>}
         <div className='hidden lg:flex'>
           <Footer/>
        </div>
    </div>
  )
}

export default AppLayout; 